.headroom {
	left: 0;
	right: 0;
	z-index: 1000 !important;

	position: fixed;
}
.headroom.bottom {
	bottom: 0;
}
.headroom--unfixed {
	transform: translateY(0);
	z-index: 1000 !important;
}
.headroom--scrolled {
	transition: transform 200ms ease-in-out;
}
.headroom--unpinned {
	transition: transform 200ms ease-in-out;
	transform: translateY(-100%);
}
.headroom--pinned {
	transition: transform 200ms ease-in-out;
	transform: translateY(0%);
}
