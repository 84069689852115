#vxvDropdown {
	left: 0;
	right: 0;
	z-index: 1000 !important;

	position: fixed;
}
#vxvDropdown.headroom.bottom {
	bottom: 0;
}
#vxvDropdown.headroom--unfixed {
	transform: translateY(0);
	z-index: 1000 !important;
}
#vxvDropdown.headroom--scrolled {
	transition: transform 200ms ease-in-out;
}
#vxvDropdown.headroom--unpinned {
	transition: transform 200ms ease-in-out;
	transform: translateY(-300%);
}
#vxvDropdown.headroom--pinned {
	transition: transform 200ms ease-in-out;
	transform: translateY(0%);
}

#vxvDropdown.headroom-wrapper {
	height: 0 !important;
}

@media screen and (max-width: 700px) {
    #vxvDropdown.headroom--unpinned {
        transform: translateY(-200%);
    }
  }

  @media screen and (max-width: 1050px) {
    #vxvDropdown.headroom--unpinned {
        transform: translateY(-150%);
    }
  }
