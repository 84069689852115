.popover{
    max-width: 533px !important;
    border-radius: 12px !important;
    border: none !important;
    background-color: #F4F1EE !important;
    overflow: hidden !important;
    max-height: 100vh !important;
}

.arrow::after{
    border-bottom-color: #F4F1EE !important;
    border-top-color: #F4F1EE !important;
    border-style: none !important;
} 