#headroom-footer {
	left: 0;
	right: 0;
	z-index: 1000 !important;
	/* transform: translate3d(0px, 0, 0px) !important; */

	position: fixed;
	/* pointer-events: none; */
	/* background: #111112;
    top: unset; 
    z-index: 989; 
    transform: translate3d(0px, 0, 0px);
    transition: all 0.2s ease-in-out 0s;
    padding: 34px 0; */
}
.headroom.bottom {
	bottom: 0;
}
#headroom-footer.headroom--unfixed {
	transform: translateY(0);
	z-index: 1000 !important;
}
#headroom-footer.headroom--scrolled {
	transition: transform 200ms ease-in-out;
}
#headroom-footer.headroom--unpinned {
	transition: transform 200ms ease-in-out;
	transform: translateY(100%);
}
#headroom-footer.headroom--pinned {
	transition: transform 200ms ease-in-out;
	transform: translateY(0%);
}

#headroom-footer.headroom-wrapper {
	height: 0 !important;
}
