@keyframes gradient {
	0%{background-position:2% 0%}

	50%{background-position:99% 100%}

	100%{background-position:2% 0%}
}

.home{
    animation: gradient 51s linear infinite alternate;
}