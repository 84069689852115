.swiper-slide.study-map {
  width: 233px !important;
  /* max-width: 85vw; */
}

/* .swiper-container {
  width: 100%;
  position: relative;
} */

@media screen and (min-width: 700px) {
  .swiper-slide.study-map {
    width: 422px !important;
    /* max-width: 45vw; */
  }

  /* .swiper-container{
		width: 422px  !important;
	} */
}

@media screen and (min-width: 1100px) {
  .swiper-slide.study-map {
    width: 422px !important;
    /* max-width: 30vw; */
  }

  /* .swiper-container{
		width: 422px  !important;
	} */
}

/* .swiper-container.swiper-container-initialized.swiper-container-horizontal{
	width: 233px !important;
	margin: 0;
} */
