.swiper-slide {
	width: auto;
	/* padding: 0 10px; */
	margin-right: 10px;
}

@media screen and (max-width: 699px) {
	.swiper-slide {
		max-width: 80vw;
	}
}

@media screen and (min-width: 700px) {
	.swiper-slide {
		margin-right: 18px;
	}
}
@media screen and (min-width: 1000px) {
	.swiper-slide {
		/* max-width: 25vw; */
	}
}
