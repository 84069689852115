#dropdownPopover{
    min-width: 300px !important;
    max-width: 533px !important;
    max-height: 436px !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    

    border-radius: 12px !important;
    border: none !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.191898) !important;
}

#dropdownPopover.arrow::after{
    border-bottom-color: #F4F1EE !important;
}